import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import darkClick from '../../assets/onDarkClick@3x.png';
import darkEnvelope from '../../assets/onDarkEnvelope@3x.png';
import selectMenu from '../../assets/EZ-Menu-icon.png';
import darkInputField from '../../assets/onDarkInputField@3x.png';
import lightLogin from '../../assets/log-in@3x.png';
import lightInput from '../../assets/edit-3@3x.png';
import lightClick from '../../assets/navigation@3x.png';
import lightEmail from '../../assets/mail@3x.png';
import lightMessage from '../../assets/message-square@3x.png';
import darkLogin from '../../assets/onDarkLogin@3x.png';
import darkText from '../../assets/onDarkText@3x.png';
import taskImage from '../../assets/task_image.png';
import menuIcon from '../../assets/menu_icon.png';
import trashIcon from '../../assets/trash_icon.png';
import editIcon from '../../assets/edit_item.png';
import unlockImage from '../../assets/onDark@3x.png';
import addIcon from '../../assets/add-icon.png';
import listIcon from '../../assets/Bulleted-List-icon.png';
import calendarIcon from '../../assets/calendar-icon.png';
import calendarIconDark from '../../assets/calendar-icon-dark.png';
import infoLight from '../../assets/Info-Light-icon.png';
import deleteIcon from '../../assets/Delete-icon.png';
import Jumbotron from 'react-bootstrap/Jumbotron';
import '../../css/createTask.css';
import NumberFormat from 'react-number-format';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from 'moment';
import historyScroll from '../../assets/62867-scroll-icon.png';
import { WithOutContext as ReactTags } from 'react-tag-input-no-dnd';


const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const handleDelete = i => {
    //setTags(tags.filter((tag, index) => index !== i));
};

const handleAddition = tag => {
    //  setTags([...tags, tag]);
};

const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
};
const popoverSearchInfo = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">How search works?</Popover.Title>
        <Popover.Content>
            Your Bot searches for text in a list and will run as normal if any list item(s) are found. If your Bot does not find any matched items, you will be notified.
        </Popover.Content>
    </Popover>
);
const popoverLoginInfo = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Is this safe?</Popover.Title>
        <Popover.Content>
            <b>Bot-It will never share your private Bot information.</b> We keep your data safely secured for your Bot to use for automation only. For more information, please read our <a target="_blank" href="https://botit.com/faq.html">FAQ</a> and <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTvBPElsSoXDn8IokGvGdUuL_wtUfhewa8t9hVkxXe1HR5nrH4-P2zVWdiCHFmUQjX7acsD7aUXfL9M/pub" >Privacy Policy</a> to see how Bot-It uses your data.
        </Popover.Content>
    </Popover>
);
const popoverDatePickerInfo = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">How does repeat work?</Popover.Title>
        <Popover.Content>
            Once your Bot has ran, it will automatically increase to a future date. Example: If repeat weekly and the date selected was the 20th. Your Bot will run the same steps but just on the 27th and keep increasing over time.
        </Popover.Content>
    </Popover>
);
function TaskItems(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Select a Step</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Button onClick={() => props.modalFactory(props.login, 'login')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={darkLogin} width="24" height="24" />
                                </Col>

                                <Col xs={10}>
                                    <h5>Login</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">When a site is requiring you to login. Add a Login step to
                                    your Bot and our encrypted system will safely secure your information.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                    <Button onClick={() => props.modalFactory(props.input, 'input')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={darkInputField} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Type or Search</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">Need your Bot to type in words on a website? Add a Typing
                                    step and your bot will type it in for you automatically.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                </Row>
                <Row>
                    <Button onClick={() => props.modalFactory(props.button, 'button')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={darkClick} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Click Button</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">Add a Button Click step when you want your Bot to click a
                                    button on the page.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo taskListItemSubscriptionInfo-pad">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                    <Button onClick={() => props.modalFactory(props.select, 'select')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={selectMenu} width="28" height="28" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Drop Down List</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">Want to select an option from a drop down list on the
                                    page? Have your Bot choose a value from a list.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                </Row>
                <Row>
                    <Button onClick={() => props.modalFactory(props.datePicker, 'datePicker')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={calendarIcon} width="32" height="32" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Pick a Date</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">Need to select a date? Have your Bot automatically select a date with the date picker step.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo taskListItemSubscriptionInfo-pad">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                    <Button onClick={() => props.modalFactory(props.listSelector, 'listSelector')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={listIcon} width="28" height="28" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Select List Item</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">With the Select Search Item step, you can select a specific item from a list on the page.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                </Row>
                <Row>
                    <Button onClick={() => props.modalFactory(props.email, 'email')} style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={darkEnvelope} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Email Results</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">Want to know what your Bot is doing at a specific
                                    step? Add a Email step and your Bot will take a snapshot of
                                    what is displayed on the screen and email it.
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                    <Button onClick={() => props.modalFactory(props.sms, 'sms')} href="#" style={{ display: 'contents' }}>
                        <Col md={5} className="taskListItem">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xs={2}>
                                    <img src={darkText} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Text Results</h5>
                                </Col>
                            </Row>
                            <Row className="taskListItemDescriptionInfo">
                                <p className="text-muted">Don't want to get emailed? Add a Text step and have your Bot
                                    take a snapshot of of what is presented on the screen and text it (standard text
                                    rates apply).
                                </p>
                                <Col lg={12} className="taskListItemSubscriptionInfo taskListItemSubscriptionInfo-pad">
                                    <p className="text-muted">Requires Standard</p>
                                </Col>
                            </Row>
                        </Col>
                    </Button>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

function UpdateRedirectUrl(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Update Redirect Url</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex justify-content-center">
                    <Form.Label>Please Enter the New Redirect Url
                    </Form.Label>
                    <Form.Control onChange={props.handleOnRedirectUrlChange} value={props.tasks[props.currentTaskPosition].redirectUrl ? props.tasks[props.currentTaskPosition].redirectUrl.url : ""} type="text" placeholder="URL" required />
                    <Form.Text className="text-muted">
                        **URL Must Begin With https**
                    </Form.Text>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onClickSaveHtmlControlRedirectUrl}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function AdminOptionPanelModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Admin Panel&nbsp;<a style={{ fontSize: "17px" }} target='_blank' href={"https://tick.botit.com/builder?bid=" + props.botId + "&linked=true"}>{props.botId}</a>&nbsp;<a target={"_blank"} href={"https://tick.botit.com?start=botHistory&bid=" + props.botId}><img src={historyScroll} width="16" height="16" /></a></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group controlId="allowCustomRedirectUrl">
                            <Form.Check type="checkbox"
                                checked={props.allowCustomRedirectUrl} label="Allow Custom Redirect" onChange={props.handleCustomRedirect} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="forceScheduleReset">
                            <Form.Check type="checkbox"
                                checked={props.forceScheduleReset} label="Force Schedule Reset After Fail" onChange={props.handleForceScheduleReset} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="extendPageLoadTimeout">
                            <Form.Check type="checkbox"
                                checked={props.extendPageLoadTimeout} label="Extend Page Load Timeout" onChange={props.handleExtendPageLoadTimeout} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="sendNoResultsEmail">
                            <Form.Check type="checkbox"
                                checked={props.sendNoResultsEmail} label="Send No Results Email" onChange={props.handleSendNoResultsEmail} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="continueAfterNoResults">
                            <Form.Check type="checkbox"
                                checked={props.continueAfterNoResults} label="Continue After No Results" onChange={props.handleContinueAfterNoResults} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="runLocally">
                            <Form.Check type="checkbox"
                                checked={props.runLocally} label="Run Bot Locally" onChange={props.handleRunLocallyOption} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="hideFailedResults">
                            <Form.Check type="checkbox"
                                checked={props.hideFailedResults} label="Hide Failed Results" onChange={props.handleHideFailedResults} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="skipRunTimeStep">
                            <Form.Check type="checkbox"
                                checked={props.skipRunTimeStep} label="Skip Run Time Step" onChange={props.handleSkipRunTimeStep} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="skipIPSetup">
                            <Form.Check type="checkbox"
                                checked={props.skipIPSetup} label="Skip IP Setup" onChange={props.handleSkipIpSetup} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="skipDateInCreaseIfFail">
                            <Form.Check type="checkbox"
                                checked={props.skipDateInCreaseIfFail} label="Skip Date Increase if Fail" onChange={props.handleSkipDateInCreaseIfFail} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={true}>
                    <Col>
                        <Form.Group controlId="startLurk">
                            <Form.Check type="checkbox"
                                checked={props.startLurk} label="Lurk Start" onChange={props.handleLurkStart} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="naturalScreenResolution">
                            <Form.Check type="checkbox"
                                checked={props.naturalScreenResolution} label="Natural Screen Resolution" onChange={props.handleNaturalScreenResolution} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="isSpreadSheetBot">
                            <Form.Check type="checkbox"
                                checked={props.isSpreadSheetBot} label="Spreadsheet Bot" onChange={props.handleIsSpreadSheetBot} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="ipSettingValue">
                            <Form.Label>Browser IP Setting</Form.Label>
                            <Form.Control as="select" onChange={props.handleIPSettingChange}>
                                {props.iPSettings.map((item) => {
                                    return <option selected={props.iPSetting == item} value={item}>{item}</option>
                                })}
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="dataCenterValue">
                            <Form.Label>Select Data Center Location</Form.Label>
                            <Form.Control as="select" onChange={props.handleDataCenterLocationChange}>
                                {props.dataCenterLocationOptions.map((item) => {
                                    return <option selected={props.dataCenterLocation == item} value={item}>{item}</option>
                                })}
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="driverHubValue">
                            <Form.Label>Select Driver Hub</Form.Label>
                            <Form.Control as="select" onChange={props.handleDriveHubChange}>
                                {props.driverHubOptions.map((item) => {
                                    return <option selected={props.driverHub == item} value={item}>{item}</option>
                                })}
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={true}>
                    <Col>
                        <Form.Group controlId="proxyGroupValue">
                            <Form.Label>Select Proxy Group</Form.Label>
                            <Form.Control as="select" onChange={props.handleProxyGroupChange}>
                                {props.proxyGroupOptions.map((item) => {
                                    return <option selected={props.proxyGroup == item} value={item}>{item}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="chromeExtensionValue">
                            <Form.Label>Select Chrome Extension</Form.Label>
                            <Form.Control as="select" onChange={props.handleChromeExtensionChange}>
                                <option value={''}>--Choose Option--</option>
                                {props.chromeExtensionOptions.map((item) => {
                                    return <option selected={props.chromeExtensionType == item} value={item}>{item}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="burstDuration">
                            <Form.Label>Bot Burst Duration</Form.Label>
                            <Form.Control type="number" placeholder='Default 3 Minute' onChange={props.handleBotDurationChange} value={props.burstDuration}>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="burstCount">
                            <Form.Label>Bot Burst Count</Form.Label>
                            <Form.Control type="number" placeholder='Default 2' onChange={props.handleBurstCountChange} value={props.burstCount}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="mainBotStartDelay">
                            <Form.Label>Bot Start Delay (Milliseconds)</Form.Label>
                            <Form.Control type="number" placeholder='Default 0' onChange={props.handleMainBotStartDelayChange} value={props.mainBotStartDelay}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="customFailCount">
                            <Form.Label>Custom Fail Count</Form.Label>
                            <Form.Control type="number" placeholder='Default 5' onChange={props.handleCustomFailCountChange} value={props.customFailCount}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="latestFailRunElapse">
                            <Form.Label>Latest Fail Run Elapse (Minutes)</Form.Label>
                            <Form.Control type="number" placeholder='Default 65 Minutes' onChange={props.handleLatestFailRunElapseChange} value={props.latestFailRunElapse}>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="staticIP">
                            <Form.Label>Static IP</Form.Label>
                            <Form.Control type="text" placeholder='Static IP' onChange={props.handleMainBotStaticIPChange} value={props.mainBotStaticIP}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="mainBotTabRecordingWebsiteTitle">
                            <Form.Label>Website Title (required for tab recording)</Form.Label>
                            <Form.Control type="text" placeholder='Website Title' onChange={props.handleTabRecordingWebsiteTitle} value={props.tabRecorderWebsiteTitle}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="browserProfileId">
                            <Form.Label>Browser Profile ID (Separate folder and profile with comma)</Form.Label>
                            <Form.Control type="text" placeholder='Example 1234,5678' onChange={props.handleBrowserProfileId} value={props.browserProfileId}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="mainBotUserId">
                            <Form.Label>Owner User ID</Form.Label>
                            <Form.Control type="text" placeholder='User ID' onChange={props.handleMainBotTransferOwnership} value={props.mainBotUserId}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="chainBotId">
                            <Form.Label>Chain Bot ID</Form.Label>
                            <Form.Control type="text" placeholder='Bot ID to Start after success' onChange={props.handleChainBotIdChange} value={props.chainBotId}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="chainBotParams">
                            <Form.Label>Chain Bot Parameters(New bot:Current bot)</Form.Label>
                            <Form.Control type="text" placeholder='name:name,address:street' onChange={props.handleChainBotParamChange} value={props.chainBotParams}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="miniBotId">
                            <Form.Label>Mini Bot ID</Form.Label>
                            <Form.Control type="text" placeholder='Mini Bot' onChange={props.handleMiniBotChange} value={props.miniBotId}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={true}>
                    <Col>
                        <Form.Group controlId="maxDelayCount">
                            <Form.Label>Child Bot Burst Max Delay</Form.Label>
                            <Form.Control type="number" placeholder='Default 21 Seconds' onChange={props.handleMaxDelayCountChange} value={props.maxDelayCount}>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={true}>
                    <Col>
                        <Form.Group controlId="botCountryValue">
                            <Form.Label>Select Bot Country</Form.Label>
                            <Form.Control as="select" onChange={props.handleBotCountryChange}>
                                {props.botCountryOptions.map((item) => {
                                    return <option selected={props.country == item} value={item}>{item}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

function TaskDetail(props) {
    return (
        <div>
            <Draggable draggableId={props.id} index={props.index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Row>
                            <Col xs={4}>
                                <span className="text-muted">Step {props.index + 1}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2} lg={1}>
                                <img src={menuIcon} alt="" />
                            </Col>
                            <Col className="itemMain" xs={7} lg={9}>
                                <RenderTaskListItem task={props.task} step={props.index} />
                            </Col>
                            <div className="actionIcons">
                                <div className="actionIcon">

                                    <Button style={{ width: '32px', height: '18px' }} variant='link' onClick={() => props.onClickEditTask(props.index)}>
                                        <img src={editIcon} alt="" width={32} height={32} />
                                    </Button>
                                </div>
                                <div>
                                    <Button style={{ width: '32px', height: '18px' }} variant='link' onClick={() => props.onClickDeleteTask(props.index)}>
                                        <img src={deleteIcon} alt="" width={32} height={32} />
                                    </Button>

                                </div>
                            </div>

                        </Row>

                    </div>
                )}
            </Draggable>
            {props.index == props.taskSize - 1 && !props.hideAssignButton &&
                <div>
                    <Row>
                        <Col xs={1}></Col>

                        {props.showUpgradeButton() ? (
                            <Col sm={10} style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Button block onClick={() => { window.location = '/subscription' }} style={{ backgroundColor: 'rgb(255 119 0)' }}><i><img height={25} width={24} src={unlockImage} /></i> Upgrade to unlock more steps</Button>
                            </Col>
                        ) : (
                            <Col sm={8} style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Button variant="success" onClick={() => props.handleTaskModalShow(true)}>
                                    <span><b>Add Step</b></span>
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <div style={{ textAlign: 'left' }}>
                                <Button variant="primary" onClick={props.onClickStepBack}>Back</Button>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: 'right' }}>
                                <Button variant="primary" onClick={props.onClickStepForward}>Finish</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>

    );
}


function RenderTaskListItem(props) {

    if (props.task.loginTask) {

        return <div>
            <div>
                <img className="itemImg" src={lightLogin} alt="" />
                <span className="itemTitle">Login</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else if (props.task.buttonTask) {
        return <div>
            <div>
                <img className="itemImg" src={lightClick} alt="" />
                <span className="itemTitle">{props.task.buttonTask.label}</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else if (props.task.textFieldTask) {
        return <div>
            <div>
                <img className="itemImg" src={lightInput} alt="" />
                <span className="itemTitle">{props.task.textFieldTask.label}</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else if (props.task.selectTask) {
        return <div>
            <div>
                <img className="itemImg" src={selectMenu} alt="" />
                <span className="itemTitle">{props.task.selectTask.label}</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else if (props.task.datePickerTask) {
        return <div>
            <div>
                <img className="itemImg" src={calendarIconDark} alt="" />
                <span className="itemTitle">{props.task.datePickerTask.label}</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else if (props.task.listSelectorTask) {
        return <div>
            <div>
                <img className="itemImg" src={menuIcon} alt="" />
                <span className="itemTitle">{props.task.listSelectorTask.label}</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else if (props.task.emailResultTask) {
        return <div>
            <div>
                <img className="itemImg" src={lightEmail} alt="" />
                <span className="itemTitle">Email Results</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }
    else {
        return <div>
            <div>
                <img className="itemImg" src={lightMessage} alt="" />
                <span className="itemTitle">Text Results</span>
                <span className="itemRightArrow">&gt;</span>
            </div>
        </div>
    }

}

const TaskList = React.memo(function TaskList({ tasks, handleTaskModalShow, hideAssignButton, onClickEditTask,
    onClickStepBack, onClickStepForward, showUpgradeButton, onClickDeleteTask }) {
    return tasks.map((task, index) => (
        <TaskDetail task={task} index={index} id={'id-' + index} taskSize={tasks.length} handleTaskModalShow={handleTaskModalShow}
            hideAssignButton={hideAssignButton} onClickEditTask={onClickEditTask} onClickStepBack={onClickStepBack}
            onClickStepForward={onClickStepForward} showUpgradeButton={showUpgradeButton} onClickDeleteTask={onClickDeleteTask} />
    ));

});

function LoginTask(props) {
    if (props.tasks[props.currentTaskPosition].loginTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Login Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="userEmail">
                                    <Form.Label>Username or Email
                                        <OverlayTrigger trigger="click" placement="left" overlay={popoverLoginInfo}>
                                            <img src={infoLight} width={24} height={24} style={{ marginLeft: "10px" }} />
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control onChange={props.handleLoginUsernameChange} value={props.tasks[props.currentTaskPosition].loginTask.userName} type="text" placeholder="Enter Username or Email" required />
                                    <Form.Text className="text-muted">
                                        **Your login information is never shared and is safely secured in our system
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="userPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleLoginPasswordChange} value={props.tasks[props.currentTaskPosition].loginTask.password} type={props.showLoginTaskPassword ? "text" : "password"} placeholder="Password" required />
                                    <Form.Group controlId="showExampleCheckbox">
                                        <Form.Check type="checkbox" onChange={props.onShowPasswordChange} label="Show Password" />
                                    </Form.Group>
                                    {props.allowCustomRedirectUrl &&
                                        <div style={{ textAlign: "center" }}>
                                            <Button variant="link" onClick={() => props.handleShowUpdateRedirectUrl(true, props.onHide)}>
                                                Update Redirect Url
                                            </Button>
                                        </div>

                                    }
                                </Form.Group>
                            </Form>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={props.disableLoginTaskButton} onClick={props.onClickSaveLoginDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    else {
        return (<div></div>);
    }
}
function InputHelperLabelTask(props) {
    if (props.tasks[props.currentTaskPosition].textFieldTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Typing Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="inputHelper">
                                    <Form.Label>Name of Field</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleInputHelperLabelChange} value={props.tasks[props.currentTaskPosition].textFieldTask.label} type="text" placeholder="Enter which Text Field" required />
                                </Form.Group>
                                <Form.Group controlId="inputValue">
                                    <Form.Label>Value for Field</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleInputValueChange} value={props.tasks[props.currentTaskPosition].textFieldTask.inputValue} type="text" placeholder="Text Field Value" required />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row hidden={!props.hasAccess && !props.isSpreadSheetBot}>
                        <Col>
                            <Form.Group controlId="isSpreadSheetFormField">
                                <Form.Check type="checkbox"
                                    checked={props.tasks[props.currentTaskPosition].isSpreadSheetFormField} label="Spreadsheet Form Field" onChange={props.handleIsSpreadSheetFormField} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={props.disableInputTaskButton} variant="primary" onClick={props.onClickSaveInputFieldDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    else {
        return (<div></div>);
    }
}
function SelectOptionHelperLabelTask(props) {
    if (props.tasks[props.currentTaskPosition].selectTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Select Option from List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="inputHelper">
                                    <Form.Label>Which Option List</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleSelectOptionHelperLabelChange} value={props.tasks[props.currentTaskPosition].selectTask.label} type="text" placeholder="Enter Which Options List" required />
                                </Form.Group>
                                <Form.Group controlId="inputValue">
                                    <Form.Label>What Value to Select</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleSelectValueChange} value={props.tasks[props.currentTaskPosition].selectTask.option} type="text" placeholder="Enter Which Value to Select" required />
                                </Form.Group>
                                {props.allowCustomRedirectUrl &&
                                    <div style={{ textAlign: "center" }}>
                                        <Button variant="link" onClick={() => props.handleShowUpdateRedirectUrl(true, props.onHide)}>
                                            Update Redirect Url
                                        </Button>
                                    </div>

                                }
                            </Form>
                        </Col>
                    </Row>
                    <Row hidden={!props.hasAccess && !props.isSpreadSheetBot}>
                        <Col>
                            <Form.Group controlId="isSpreadSheetFormField">
                                <Form.Check type="checkbox"
                                    checked={props.tasks[props.currentTaskPosition].isSpreadSheetFormField} label="Spreadsheet Form Field" onChange={props.handleIsSpreadSheetFormField} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={props.disableSelectTaskButton} variant="primary" onClick={props.onClickSaveSelectOptionDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    else {
        return (<div></div>);
    }
}

function DatePickerOptionTask(props) {
    if (props.tasks[props.currentTaskPosition].datePickerTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Select Date Picker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="inputHelper">
                                    <Form.Label>Which Calendar on the Page?</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleDatePickerOptionChange} value={props.tasks[props.currentTaskPosition].datePickerTask.label} type="text" placeholder="example: start date, end date, appointment" required />
                                </Form.Group>
                                <Form.Group controlId="inputValue">
                                    <Form.Label>Select a future date <span style={{ fontSize: "10px" }}>{props.targetBookingDate ? "(Target: " + props.targetBookingDate + ")" : ""} {props.advanceBookingDays ? props.advanceBookingDays + " days advance" : ""}</span></Form.Label>
                                    {props.hasAccess ? (
                                        <Calendar
                                            onChange={props.handleDatePickerValueChange}
                                            value={props.tasks[props.currentTaskPosition].datePickerTask.option ?
                                                moment(props.tasks[props.currentTaskPosition].datePickerTask.option.replace(/-/g, '\/').replace(/T.+/, '')).toDate() : new Date()}
                                        />) : (<Calendar
                                            minDate={new Date()}
                                            onChange={props.handleDatePickerValueChange}
                                            value={props.tasks[props.currentTaskPosition].datePickerTask.option ?
                                                moment(props.tasks[props.currentTaskPosition].datePickerTask.option.replace(/-/g, '\/').replace(/T.+/, '')).toDate() : new Date()}
                                        />)
                                    }

                                </Form.Group>
                                <Form.Group controlId="additionalValue">
                                    <Form.Label>Repeat from selected date:</Form.Label><OverlayTrigger trigger="click" placement="left" overlay={popoverDatePickerInfo}>
                                        <img src={infoLight} width={24} height={24} style={{ marginLeft: "10px" }} />
                                    </OverlayTrigger>
                                    <Form.Control as="select" onChange={props.handleDatePickerRepeaterChange}>
                                        {props.repeatersAllowed.map((item) => {
                                            return <option selected={props.tasks[props.currentTaskPosition].datePickerTask.daysAutoIncreaseDateValue == item} value={item}>{item}</option>
                                        })}
                                    </Form.Control>

                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row hidden={!props.hasAccess && !props.isSpreadSheetBot}>
                        <Col>
                            <Form.Group controlId="isSpreadSheetFormField">
                                <Form.Check type="checkbox"
                                    checked={props.tasks[props.currentTaskPosition].isSpreadSheetFormField} label="Spreadsheet Form Field" onChange={props.handleIsSpreadSheetFormField} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={props.disableDatePickerTaskButton} variant="primary" onClick={props.onClickSaveDatePickerOptionDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    else {
        return (<div></div>);
    }
}
function ListSelectorOptionTask(props) {
    if (props.tasks[props.currentTaskPosition].listSelectorTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Click a list item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="inputHelper">
                                    <Form.Label>Which list on the page? <OverlayTrigger trigger="click" placement="left" overlay={popoverSearchInfo}>
                                        <img src={infoLight} width={24} height={24} style={{ marginLeft: "10px" }} />
                                    </OverlayTrigger></Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleListSelectorOptionChange} value={props.tasks[props.currentTaskPosition].listSelectorTask.label} type="text" placeholder="example: search results, menu, articles" required />
                                </Form.Group>
                                <Form.Group controlId="inputValue">
                                    <Row>
                                        <Col sm="6" md="12" lg="12"><Form.Label>Which item(s) to select?</Form.Label><a hidden={true} onClick={props.addSearchOption} style={{ marginLeft: "12px" }}><img src={addIcon} /></a></Col>
                                    </Row>
                                    {props.tasks[props.currentTaskPosition].listSelectorTask.options.map((item, i) => (
                                        <div >
                                            {i == 0 ?
                                                <Row>
                                                    <Col xs={10}>
                                                        <ReactTags
                                                            classNames={{
                                                                tagInputField: 'form-control test'
                                                            }}
                                                            tags={props.tags}
                                                            delimiters={delimiters}
                                                            handleDelete={props.removeTag}
                                                            handleAddition={props.addTag}
                                                            allowDeleteFromEmptyInput={false}
                                                            inputProps={{
                                                                compareIndex: i,
                                                                exactMatch: item.exactMatch,
                                                                autocomplete: "off"
                                                            }}
                                                            inputFieldPosition="top"
                                                            placeholder="Press enter after typing item"
                                                            style={{ marginBottom: '5px' }}
                                                            ref={props.setTextInputRef}
                                                        />
                                                        {/*   <Form.Control autoComplete="off" onChange={props.handleListSelectorValueOptionChange(i, item.exactMatch)} value={item.compareText} type="text" placeholder="example: winter, burger, green" style={{ marginBottom: '5px' }} required />
                                                        <Form.Check type="checkbox" checked={item.exactMatch} onChange={props.handleListSelectorExactMatchValueOptionChange(i, item.compareText)} label="Match Exactly?" /> */}
                                                    </Col>
                                                </Row>
                                                :
                                                <div >
                                                    <Row>
                                                        <Col xs={10} >
                                                            <Form.Group controlId={"formBasicCheckbox" + i} >
                                                                <ReactTags
                                                                    classNames={{
                                                                        tagInputField: 'form-control test'
                                                                    }}
                                                                    tags={props.tags}
                                                                    delimiters={delimiters}
                                                                    handleDelete={props.removeTag}
                                                                    handleAddition={props.addTag}
                                                                    allowDeleteFromEmptyInput={false}
                                                                    inputProps={{
                                                                        compareIndex: i,
                                                                        exactMatch: item.exactMatch,
                                                                        autocomplete: "off"
                                                                    }}
                                                                    inputFieldPosition="top"
                                                                    placeholder="Press enter after typing item"
                                                                    style={{ marginBottom: '5px' }}
                                                                    ref={props.setTextInputRef}
                                                                />
                                                                {/*<Form.Control autoComplete="off" onChange={props.handleListSelectorValueOptionChange(i, item.exactMatch)} value={item.compareText} type="text" placeholder="example: spice, ohio, jazz" style={{ marginBottom: '5px' }} required />
                                                                <Form.Check type="checkbox" checked={item.exactMatch} onChange={props.handleListSelectorExactMatchValueOptionChange(i, item.compareText)} label="Match Exactly?" /> */}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <a onClick={() => props.deleteItem(i)}>
                                                                <img width={16} height={16} src={deleteIcon} />
                                                            </a>
                                                        </Col>
                                                    </Row>

                                                </div>

                                            }

                                        </div>
                                    ))}

                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row hidden={!props.hasAccess && !props.isSpreadSheetBot}>
                        <Col>
                            <Form.Group controlId="isBeforeSpreadSheetForm">
                                <Form.Check type="checkbox"
                                    checked={props.tasks[props.currentTaskPosition].isBeforeSpreadSheetForm} label="Enable Spreadsheet Column Reader" onChange={props.handleIsSpreadSheetBeforeForm} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={props.disableListSelectorTaskButton} variant="primary" onClick={props.onClickSaveListSelectorOptionDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    else {
        return (<div></div>);
    }
}
function ButtonHelperLabelTask(props) {
    if (props.tasks[props.currentTaskPosition].buttonTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Button Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="buttonHelper">
                                    <Form.Label>Which Button to Click</Form.Label>
                                    <Form.Control autoComplete="off" onChange={props.handleButtonHelperLabelChange} value={props.tasks[props.currentTaskPosition].buttonTask.label} type="text" placeholder="Enter which button" required />
                                </Form.Group>
                                {props.allowCustomRedirectUrl &&
                                    <div style={{ textAlign: "center" }}>
                                        <Button variant="link" onClick={() => props.handleShowUpdateRedirectUrl(true, props.onHide)}>
                                            Update Redirect Url
                                        </Button>
                                    </div>
                                }
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={props.disableButtonTaskButton} variant="primary" onClick={props.onClickSaveButtonDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return (<div></div>);
    }
}
function EmailTask(props) {
    if (props.tasks[props.currentTaskPosition].emailResultTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Email Bot Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="emailDetails">
                                    <Form.Label>Email Bot Results To:</Form.Label>
                                    <Form.Control onChange={props.handleEmailChange} value={props.tasks[props.currentTaskPosition].emailResultTask.emailAddress} type="email" placeholder="Email Address" required />
                                </Form.Group>
                            </Form>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={props.disableEmailResultsButton} variant="primary" onClick={props.onClickSaveEmailDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return (<div></div>);
    }

}
function SmsTask(props) {
    if (props.tasks[props.currentTaskPosition].sMSResultsTask) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Text Bot Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group controlId="smsDetails">
                                    <Form.Label>Text Bot Results To (US or Canada):</Form.Label><br />
                                    <NumberFormat
                                        autoComplete="off" onChange={props.handleSmsChange} value={props.tasks[props.currentTaskPosition].sMSResultsTask.phoneNumber}
                                        type="tel"
                                        format="###-###-####"
                                        className={"form-control"}
                                        placeholder="000-000-0000"
                                        required />
                                </Form.Group>
                            </Form>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={props.disableSmsResultsButton} onClick={props.onClickSaveSmsDetails}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return (<div></div>);
    }
}
function ConfirmTaskDelete(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="smsDetails">
                                <Form.Label>Are you sure you want to delete {props.pendingDeleteTask.message}?</Form.Label>
                            </Form.Group>
                        </Form>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={props.onClickConfirmDeleteTask}>
                    Yes Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function LoginConsentWarning(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Bot-It ⚠️</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>In order to setup your Bot, The Bot-It Team may have to login to this site with the information you provided.
                            Your privacy & data is safe and secure and will only be used by a Bot-It team member for setup.
                            Do we have your permission?</p>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onAllowConsentClick}>
                    Yes
                </Button>
                <Button variant="danger" onClick={props.onDoNotAllowConsentClick}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export const StepThreeTask = inject("rootStore")(observer((props) => (
    <div>
        <Jumbotron hidden={props.step == 3 ? false : true} style={{ backgroundColor: 'rgb(244 248 250)' }}>
            <div >
                {
                    props.tasks.length == 0 ?
                        (
                            <div style={{ marginTop: "30px" }}>
                                <Row style={{ marginTop: '20px', textAlign: "center" }} >
                                    <Col xs={12}>
                                        <h3>Start Adding Bot Steps</h3>
                                    </Col>

                                </Row>
                                <Row style={{ textAlign: "center" }} >
                                    <Col xs={12}>
                                        <img src={taskImage} />
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "center", marginTop: '20px' }} >
                                    <Col xs={12}>
                                        {props.showUpgradeButton() ? (
                                            <Button onClick={() => { window.location = '/subscription' }} style={{ backgroundColor: 'rgb(255 119 0)' }}><i><img height={25} width={24} src={unlockImage} /></i> Upgrade to unlock more steps</Button>
                                        ) : (
                                            <Button type="button" onClick={() => props.handleTaskModalShow(true)}>Add Step</Button>

                                        )}

                                    </Col>

                                </Row>
                            </div>
                        ) : (
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <Button variant="link" onClick={() => props.handleShowAdminPanelModal(true)} hidden={!props.hasAccess}>Admin</Button>
                                </div>
                                <DragDropContext onDragEnd={props.onTaskDragEnd} onDragStart={props.onTaskDragStart}>
                                    <Droppable droppableId="list">
                                        {provided => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                <TaskList tasks={props.tasks} taskSize={props.tasks.length}
                                                    handleTaskModalShow={props.handleTaskModalShow} hideAssignButton={props.hideAssignButton}
                                                    onClickEditTask={props.onClickEditTask} onClickStepBack={props.onClickStepBack}
                                                    onClickStepForward={props.onClickStepForward}
                                                    showUpgradeButton={props.showUpgradeButton} onClickDeleteTask={props.onClickDeleteTask} />
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                            </div>
                        )
                }
                <TaskItems
                    show={props.modalShow}
                    onHide={() => props.handleTaskModalShow(false)}
                    modalFactory={props.modalFactory}
                    taskCounter={props.tasks.length}
                    login={props.handleLoginModalShow}
                    select={props.handleSelectOptionHelperLabelModalShow}
                    input={props.handleInputHelperLabelModalShow}
                    button={props.handleButtonHelperLabelModalShow}
                    datePicker={props.handleDatePickerModalShow}
                    listSelector={props.handleListSelectorModalShow}
                    email={props.handleEmailModalShow}
                    sms={props.handleSmsModalShow}
                />
                {
                    props.tasks.length > 0 &&
                    <div>
                        <LoginTask
                            show={props.loginModalShow}
                            onHide={() => props.handleLoginModalShow(false, true)}
                            tasks={props.tasks}
                            handleLoginUsernameChange={props.handleLoginUsernameChange}
                            handleLoginPasswordChange={props.handleLoginPasswordChange}
                            onClickSaveLoginDetails={props.onClickSaveLoginDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            showLoginTaskPassword={props.showLoginTaskPassword}
                            onShowPasswordChange={props.onShowPasswordChange}
                            disableLoginTaskButton={props.disableLoginTaskButton}
                            allowCustomRedirectUrl={props.allowCustomRedirectUrl}
                            handleShowUpdateRedirectUrl={props.handleShowUpdateRedirectUrl}
                        />
                        <InputHelperLabelTask
                            show={props.inputLabelHelperModalShow}
                            onHide={() => props.handleInputHelperLabelModalShow(false, true)}
                            tasks={props.tasks}
                            handleInputHelperLabelChange={props.handleInputHelperLabelChange}
                            handleInputValueChange={props.handleInputValueChange}
                            handleIsSpreadSheetFormField={props.handleIsSpreadSheetFormField}
                            onClickSaveInputFieldDetails={props.onClickSaveInputFieldDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableInputTaskButton={props.disableInputTaskButton}
                            isSpreadSheetBot={props.isSpreadSheetBot}
                            hasAccess={props.hasAccess}
                        />
                        <SelectOptionHelperLabelTask
                            show={props.selectOptionLabelHelperModalShow}
                            onHide={() => props.handleSelectOptionHelperLabelModalShow(false, true)}
                            tasks={props.tasks}
                            handleSelectOptionHelperLabelChange={props.handleSelectOptionHelperLabelChange}
                            handleSelectValueChange={props.handleSelectValueChange}
                            handleIsSpreadSheetFormField={props.handleIsSpreadSheetFormField}
                            onClickSaveSelectOptionDetails={props.onClickSaveSelectOptionDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableSelectTaskButton={props.disableSelectTaskButton}
                            allowCustomRedirectUrl={props.allowCustomRedirectUrl}
                            handleShowUpdateRedirectUrl={props.handleShowUpdateRedirectUrl}
                            isSpreadSheetBot={props.isSpreadSheetBot}
                            hasAccess={props.hasAccess}
                        />
                        <ButtonHelperLabelTask
                            show={props.buttonLabelHelperModalShow}
                            tasks={props.tasks}
                            onHide={() => props.handleButtonHelperLabelModalShow(false, true)}
                            handleButtonHelperLabelChange={props.handleButtonHelperLabelChange}
                            onClickSaveButtonDetails={props.onClickSaveButtonDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableButtonTaskButton={props.disableButtonTaskButton}
                            allowCustomRedirectUrl={props.allowCustomRedirectUrl}
                            handleShowUpdateRedirectUrl={props.handleShowUpdateRedirectUrl}
                        />
                        <DatePickerOptionTask
                            show={props.datePickerHelperModalShow}
                            onHide={() => props.handleDatePickerModalShow(false, true)}
                            tasks={props.tasks}
                            handleDatePickerOptionChange={props.handleDatePickerOptionChange}
                            handleDatePickerValueChange={props.handleDatePickerValueChange}
                            handleDatePickerRepeaterChange={props.handleDatePickerRepeaterChange}
                            handleIsSpreadSheetFormField={props.handleIsSpreadSheetFormField}
                            onClickSaveDatePickerOptionDetails={props.onClickSaveDatePickerOptionDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableDatePickerTaskButton={props.disableDatePickerTaskButton}
                            repeatersAllowed={props.repeatersAllowed}
                            hasAccess={props.hasAccess}
                            isSpreadSheetBot={props.isSpreadSheetBot}
                            targetBookingDate={props.targetBookingDate}
                            advanceBookingDays={props.advanceBookingDays}
                        />
                        <ListSelectorOptionTask
                            show={props.listSelectorHelperModalShow}
                            onHide={() => props.handleListSelectorModalShow(false, true)}
                            tasks={props.tasks}
                            handleListSelectorOptionChange={props.handleListSelectorOptionChange}
                            handleListSelectorColumnOptionChange={props.handleListSelectorColumnOptionChange}
                            handleListSelectorValueOptionChange={props.handleListSelectorValueOptionChange}
                            handleListSelectorAddNewOption={props.handleListSelectorAddNewOption}
                            onClickSaveListSelectorOptionDetails={props.onClickSaveListSelectorOptionDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableListSelectorTaskButton={props.disableListSelectorTaskButton}
                            handleIsSpreadSheetBeforeForm={props.handleIsSpreadSheetBeforeForm}
                            addSearchOption={props.addSearchOption}
                            deleteItem={props.deleteItem}
                            hideAddNewSearchOption={props.hideAddNewSearchOption}
                            handleListSelectorExactMatchValueOptionChange={props.handleListSelectorExactMatchValueOptionChange}
                            tags={props.tags}
                            addTag={props.addTag}
                            removeTag={props.removeTag}
                            setTextInputRef={props.setTextInputRef}
                            hasAccess={props.hasAccess}
                            isSpreadSheetBot={props.isSpreadSheetBot}
                        />
                        <EmailTask
                            show={props.emailModalShow}
                            onHide={() => props.handleEmailModalShow(false, true)}
                            tasks={props.tasks}
                            handleEmailChange={props.handleEmailChange}
                            onClickSaveEmailDetails={props.onClickSaveEmailDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableEmailResultsButton={props.disableEmailResultsButton}
                        />
                        <SmsTask
                            show={props.smsModalShow}
                            onHide={() => props.handleSmsModalShow(false, true)}
                            tasks={props.tasks}
                            handleSmsChange={props.handleSmsChange}
                            onClickSaveSmsDetails={props.onClickSaveSmsDetails}
                            currentTaskPosition={props.currentTaskPosition}
                            disableSmsResultsButton={props.disableSmsResultsButton}
                        />
                        <ConfirmTaskDelete
                            show={props.confirmDeleteModalShow}
                            onHide={() => props.handleConfirmDeleteShow(false, true)}
                            onClickConfirmDeleteTask={props.onClickConfirmDeleteTask}
                            pendingDeleteTask={props.pendingDeleteTask}
                        />
                        <LoginConsentWarning
                            show={props.showLoginConsentWarning}
                            onHide={() => props.handleShowLoginConsentWarning(false, true)}
                            onAllowConsentClick={props.onAllowConsentClick}
                            onDoNotAllowConsentClick={props.onDoNotAllowConsentClick}
                        />
                        <UpdateRedirectUrl
                            show={props.showUpdateRedirectUrlModalShow}
                            onHide={() => props.handleShowUpdateRedirectUrl(false, false)}
                            handleOnRedirectUrlChange={props.handleOnRedirectUrlChange}
                            onClickSaveHtmlControlRedirectUrl={props.onClickSaveHtmlControlRedirectUrl}
                            redirectUrl={props.redirectUrl}
                            tasks={props.tasks}
                            currentTaskPosition={props.currentTaskPosition}
                        />
                        <AdminOptionPanelModal
                            show={props.adminPanelModalShow}
                            onHide={() => props.handleShowAdminPanelModal(false)}
                            handleCustomRedirect={props.handleCustomRedirect}
                            allowCustomRedirectUrl={props.allowCustomRedirectUrl}
                            handleForceScheduleReset={props.handleForceScheduleReset}
                            handleExtendPageLoadTimeout={props.handleExtendPageLoadTimeout}
                            handleSendNoResultsEmail={props.handleSendNoResultsEmail}
                            handleContinueAfterNoResults={props.handleContinueAfterNoResults}
                            forceScheduleReset={props.forceScheduleReset}
                            extendPageLoadTimeout={props.extendPageLoadTimeout}
                            sendNoResultsEmail={props.sendNoResultsEmail}
                            continueAfterNoResults={props.continueAfterNoResults}
                            handleDataCenterLocationChange={props.handleDataCenterLocationChange}
                            dataCenterLocationOptions={props.dataCenterLocationOptions}
                            dataCenterLocation={props.dataCenterLocation}
                            iPSetting={props.iPSetting}
                            iPSettings={props.iPSettings}
                            handleIPSettingChange={props.handleIPSettingChange}
                            driverHubOptions={props.driverHubOptions}
                            handleDriveHubChange={props.handleDriveHubChange}
                            handleMaxDelayCountChange={props.handleMaxDelayCountChange}
                            driverHub={props.driverHub}
                            handleProxyGroupChange={props.handleProxyGroupChange}
                            proxyGroupOptions={props.proxyGroupOptions}
                            handleChromeExtensionChange={props.handleChromeExtensionChange}
                            chromeExtensionOptions={props.chromeExtensionOptions}
                            chromeExtensionType={props.chromeExtensionType}
                            botCountryOptions={props.botCountryOptions}
                            proxyGroup={props.proxyGroup}
                            startLurk={props.startLurk}
                            isSpreadSheetBot={props.isSpreadSheetBot}
                            handleLurkStart={props.handleLurkStart}
                            handleIsSpreadSheetBot={props.handleIsSpreadSheetBot}
                            mainBotStartDelay={props.mainBotStartDelay}
                            customFailCount={props.customFailCount}
                            latestFailRunElapse={props.latestFailRunElapse}
                            mainBotUserId={props.mainBotUserId}
                            tabRecorderWebsiteTitle={props.tabRecorderWebsiteTitle}
                            browserProfileId={props.browserProfileId}
                            naturalScreenResolution={props.naturalScreenResolution}
                            chainBotId={props.chainBotId}
                            chainBotParams={props.chainBotParams}
                            mainBotStaticIP={props.mainBotStaticIP}
                            emailAddress={props.emailAddress}
                            burstDuration={props.burstDuration}
                            burstCount={props.burstCount}
                            country={props.country}
                            maxDelayCount={props.maxDelayCount}
                            miniBotId={props.miniBotId}
                            handleBotDurationChange={props.handleBotDurationChange}
                            handleBurstCountChange={props.handleBurstCountChange}
                            handleBotCountryChange={props.handleBotCountryChange}
                            handleMainBotStartDelayChange={props.handleMainBotStartDelayChange}
                            handleCustomFailCountChange={props.handleCustomFailCountChange}
                            handleLatestFailRunElapseChange={props.handleLatestFailRunElapseChange}
                            handleMainBotTransferOwnership={props.handleMainBotTransferOwnership}
                            handleMiniBotChange={props.handleMiniBotChange}
                            handleTabRecordingWebsiteTitle={props.handleTabRecordingWebsiteTitle}
                            handleBrowserProfileId={props.handleBrowserProfileId}
                            handleNaturalScreenResolution={props.handleNaturalScreenResolution}
                            handleChainBotIdChange={props.handleChainBotIdChange}
                            handleChainBotParamChange={props.handleChainBotParamChange}
                            handleMainBotStaticIPChange={props.handleMainBotStaticIPChange}
                            botId={props.botId}
                            runLocally={props.runLocally}
                            hideFailedResults={props.hideFailedResults}
                            skipRunTimeStep={props.skipRunTimeStep}
                            handleRunLocallyOption={props.handleRunLocallyOption}
                            handleHideFailedResults={props.handleHideFailedResults}
                            handleSkipRunTimeStep={props.handleSkipRunTimeStep}
                            handleSkipIpSetup={props.handleSkipIpSetup}
                            handleSkipDateInCreaseIfFail={props.handleSkipDateInCreaseIfFail}
                            skipIPSetup={props.skipIPSetup}
                            skipDateInCreaseIfFail={props.skipDateInCreaseIfFail}
                        />

                    </div>}

            </div >
        </Jumbotron>
        {props.dragInProgress &&
            <Row className="trashItem">
                <Col style={{ textAlign: 'center' }}>
                    <img src={trashIcon} />
                </Col>
            </Row>
        }
    </div>

)));
